import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Input } from "../components/Input";
import TagInputWithAutocomplete from "../components/TagInputWithAutocomplete";
import { useEventStream } from "../context/EventStreamingContext";
import { LinkButton, PrimaryButtonSmall } from "../components/Button";
import useImageResizer from "../utils/useImageResizer";
import { v4 as uuidv4 } from "uuid";
import { Checkbox } from "../components/Checkbox";

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  GeoPoint,
  updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db, storage } from "../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { GlobalContext } from "../context/GlobalContext";
import { fetchUsersWithIDs } from "./utils";

export const FormSection = () => {
  const { eventData, loading, setEventUsersProfile } = useEventStream();
  const { globalState } = useContext(GlobalContext);
  const { setImageForResize } = useImageResizer(600, 800);
  const [usersDb, setUsersDB] = useState([]);

  const getEventDataObject = (event) => {

    return {
      id: event?.id || null,
      name: event?.name || "",
      date: event?.date ? event.date.toDate() : new Date(),
      group: event?.group || { sitters: "", movers: "" },
      photos: event?.photos || [],
      description: event?.description || "",
      hostname: event?.hostname || "",
      address: event?.address || "",
      ageRange: event?.ageRange || "",
      coordinates: {
        latitude: event?.coordinates?.latitude || "",
        longitude: event?.coordinates?.longitude || "",
      },
      movers: event?.movers || [],
      sitters: event?.sitters || [],
      isActive: event?.isActive || false,
      isCompleted: event?.isCompleted || false,
    }
  }
  const [event, setEvent] = useState(getEventDataObject(eventData));


  useEffect(() => {
    // Update form with latest event data
    setEvent(getEventDataObject(eventData));
  }, [eventData]);

  const navigate = useNavigate();

  useEffect(() => {
    const loadUsersWithNames = async () => {
      try {
        const allUsers = await fetchUsersWithIDs(db, [
          ...event.movers,
          ...event.sitters,
        ]);
        setUsersDB(allUsers);
        setEventUsersProfile(allUsers);
      } catch (error) {
        console.error("Error fetching users: ", error);
      }
    };

    loadUsersWithNames();
  }, [db, event.movers, event.sitters]); // Dependencies

  const handleChange = (field, value) => {
    setEvent((prev) => ({ ...prev, [field]: value }));
  };

  const handleNestedChange = (field, subfield, value) => {
    setEvent((prev) => ({
      ...prev,
      [field]: { ...prev[field], [subfield]: value },
    }));
  };

  const handlePhotoChange = async (e) => {
    const files = e.target.files;
    const newPhotos = await Promise.all(
      Array.from(files).map((photo) => setImageForResize(photo))
    );

    handleChange("photos", [...(event.photos || []), ...newPhotos]);
  };

  const removePhoto = (index) => {
    const updatedPhotos = event.photos.filter((_, idx) => idx !== index);
    handleChange("photos", updatedPhotos);
  };

  const handleDateChange = (value) => {
    handleChange("date", value);
  };

  const deleteEvent = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    if (window.confirm("Are you sure you want to delete this event?")) {
      const docRef = doc(db, "events", eventData?.id);
      await deleteDoc(docRef)
        .then(() => alert("Event deleted successfully!"))
        .catch((error) => console.error("Error deleting document: ", error));
      navigate("/events");
    }
  };

  const photoPreviews = event.photos?.map((photo, index) => {
    const url = typeof photo === "string" ? photo : URL.createObjectURL(photo);
    return (
      <div className="photoPreviewItem" key={index}>
        <img
          src={url}
          alt={`preview-${index}`}
          className="w-24 h-24 rounded-md mr-2"
        />
        <button
          type="button"
          onClick={() => removePhoto(index)}
          className="text-red-600"
        >
          Remove
        </button>
      </div>
    );
  });

  // Creating or updating an event
  const handleSubmit = async (e) => {
    e.preventDefault();
    const numericLatitude = parseFloat(event.coordinates.latitude);
    const numericLongitude = parseFloat(event.coordinates.longitude);
    const geoPoint = new GeoPoint(numericLatitude, numericLongitude);

    const isValidDate = (date) => {
      const isDateObj = event.date instanceof Date;
      if (date && isDateObj) return date;
      else return new Date();
    };

    const eventDate = isValidDate(event.date);

    // Updating an existing event
    if (event.id) {
      const eventID = event.id;

      const photoURLs =
        event.photos.length > 0
          ? await Promise.all(
            event.photos.map(async (photo) => {
              if (typeof photo === "string") {
                return photo;
              }

              const uniqueName = `${uuidv4()}_${photo.name}`;

              const photoRef = ref(
                storage,
                `event/${eventID || "new"}/eventPhotos/${uniqueName}`
              );

              await uploadBytes(photoRef, photo);
              return getDownloadURL(photoRef);
            })
          )
          : [];

      const updatedEventData = {
        ...event,
        photos: photoURLs,
        coordinates: geoPoint,
        date: eventDate,
        group: event.group,
      };

      const docRef = doc(db, "events", eventID);

      await updateDoc(docRef, updatedEventData)
        .then(() => alert("Event updated successfully with photos!"))
        .catch((error) =>
          console.error("Error updating document with photos: ", error)
        );
      navigate(`/events/${docRef.id}/edit`);
    } else {
      // Creating a new event
      const eventDataForSaving = {
        ...event,
        coordinates: geoPoint,
        photos: [],
        date: eventDate,
        group: event.group,
        vendorID: globalState.vendorID,
      };

      const docRef = await addDoc(collection(db, "events"), eventDataForSaving);

      const photoURLs =
        event.photos.length > 0
          ? await Promise.all(
            event.photos.map(async (photo) => {
              const photoRef = ref(
                storage,
                `event/${docRef.id}/eventPhotos/${photo.name}`
              );
              await uploadBytes(photoRef, photo);
              return getDownloadURL(photoRef);
            })
          )
          : [];

      await updateDoc(docRef, { photos: photoURLs, id: docRef.id });
      alert("Event created successfully with photos");
      navigate(`/events/${docRef.id}/edit`);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="space-y-6">
          <div>
            <Input
              label="Name"
              value={event.name}
              type="text"
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </div>
          <div>
            <label
              htmlFor="date"
              className="block text-sm font-medium text-gray-700"
            >
              Event Date & Time
            </label>
            <DatePicker
              selected={event.date || new Date()}
              onChange={handleDateChange}
              showTimeSelect
              dateFormat="Pp"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>

          <div>
            <label
              htmlFor="group"
              className="block text-sm font-medium text-gray-700"
            >
              Group Names
            </label>
            <div className="mt-1 flex ">
              <Input
                label="Sitters"
                value={event.group.sitters}
                type="text"
                className="mr-2"
                onChange={(e) => handleNestedChange("group", "sitters", e.target.value)}
              />
              <Input
                label="Movers"
                value={event.group.movers}
                type="text"
                onChange={(e) => handleNestedChange("group", "movers", e.target.value)}
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="photos"
              className="block text-sm font-medium text-gray-700"
            >
              Photos
            </label>
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={handlePhotoChange}
              className="mt-1 block w-full text-gray-500"
            />
            <div className="flex mt-2 flex-wrap">{photoPreviews}</div>
          </div>
          <div>
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700"
            >
              Description
            </label>
            <textarea
              id="description"
              value={event.description}
              onChange={(e) => handleChange("description", e.target.value)}
              rows={4}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primaryDark focus:ring-primaryDark sm:text-sm"
            ></textarea>
          </div>
          <div>
            <Input
              label="Host Name"
              value={event.hostname}
              type="text"
              onChange={(e) => handleChange("hostname", e.target.value)}
            />
          </div>
          <div>
            <Input
              label="Address"
              value={event.address}
              type="text"
              onChange={(e) => handleChange("address", e.target.value)}
            />
          </div>
          <div>
            <Input
              label="Age Range (e.g. 20-30)"
              value={event.ageRange}
              type="text"
              onChange={(e) => handleChange("ageRange", e.target.value)}
            />
          </div>
          <div>
            <Input
              label="Latitude"
              value={event.coordinates.latitude}
              type="text"
              onChange={(e) =>
                handleNestedChange("coordinates", "latitude", e.target.value)
              }
            />
          </div>
          <div>
            <Input
              label="Longitude"
              value={event.coordinates.longitude}
              type="text"
              onChange={(e) =>
                handleNestedChange("coordinates", "longitude", e.target.value)
              }
            />
          </div>


          <div>
            <TagInputWithAutocomplete
              label={`Movers (${event.movers.length})`}
              placeholder="Add Movers"
              value={event.movers}
              onChange={(newValue) => handleChange("movers", newValue)}
              usersDb={usersDb}
              gender="Male"
            />
            <TagInputWithAutocomplete
              label={`Sitters (${event.sitters.length})`}
              placeholder="Add Sitters"
              value={event.sitters}
              onChange={(newValue) => handleChange("sitters", newValue)}
              usersDb={usersDb}
              gender="Female"
            />
          </div>
          <div className="flex justify-evenly ">
            <Checkbox
              label="Active"
              checked={event.isActive}
              onChange={(e) => handleChange("isActive", e.target.checked)}
            />
            <Checkbox
              label="Completed"
              checked={event.isCompleted}
              onChange={(e) => handleChange("isCompleted", e.target.checked)}
            />
          </div>

          <div className="mt-6 flex items-center justify-between">
            {eventData?.id ? (
              <LinkButton onClick={deleteEvent}>Delete event</LinkButton>
            ) : (
              <p></p>
            )}
            <div className="flex items-center gap-x-6">
              <PrimaryButtonSmall type="submit">
                {eventData?.id ? "Update Event" : "Create Event"}
              </PrimaryButtonSmall>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
