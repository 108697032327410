import React, { useContext, useState } from "react";
import { useEventStreamChat } from "../context/EventStreamingChatContext";
import { GlobalContext } from "../context/GlobalContext";
import { Chat } from "../components/Chat";

export const Chats = () => {
  const { mensChat, womensChat, loading } = useEventStreamChat();
  const { globalState } = useContext(GlobalContext);

  if (loading) {
    return <div>Loading...</div>;
  }
  console.log(globalState.user?.userID);
  return (
    <div className="grid lg:grid-cols-2 gap-4 grid-cols-1">
      <Chat
        title="Movers's Chat"
        chatData={mensChat}
        currentUserID={globalState.user?.userID}
        subCollectionName={"MensChat"}
      />
      <Chat
        title="Sitters's Chat"
        chatData={womensChat}
        currentUserID={globalState.user?.userID}
        subCollectionName={"WomensChat"}
      />
    </div>
  );
};
