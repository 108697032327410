import React, { useEffect, useState } from "react";
import { useEventStream } from "../context/EventStreamingContext";
import { Field, Label, Switch } from "@headlessui/react";
import { SessionMetrics } from "./SessionMetrics";
import {
  LinkButton,
  PrimaryButtonSmall,
  SecondaryButtonSmall,
  SecondaryDangerButtonSmall,
} from "../components/Button";
import QueueTable from "../../src/components/QueueTable";
import {
  rotateMoversQueue,
  generateQueue,
  regenerateQueue,
  removeRemovedUsersFromQueue,
  getRemovedUsers,
  getAddedUsers,
} from "../utils/queue";
import { Waitlist } from "./Waitlist";
import { db } from "../firebase";
import { doc, updateDoc } from "firebase/firestore";
import EventAPI from "../api/EventApi";
import { cloudFunctionsAPI } from "../firebase";
import Loader from "../components/Loader";
import Utility from "../utils/utility";
import { showToast } from "../utils/showToast";
import { useNavigate } from "react-router-dom";
import { RemovedList } from "./RemovedList";

export const QueueSection = () => {
  const navigate = useNavigate();
  const [lateComers, setLateComers] = useState(null);
  const [removedUsers, setRemovedUsers] = useState([]);
  const [newQueue, setNewQueue] = useState(null);
  const { eventData, loading, eventUsersProfile } = useEventStream();
  const [editEnabled, setEditEnabled] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [queueLength, setQueueLength] = useState(
    eventData.queue?.movers?.length || 0
  );

  const getRotatedQueue = (queue) => {
    if (!queue) return queue;
    if (!eventData.activeSession) return queue;
    if (!eventData.activeSession.currentRound) return queue;

    const roatedMenQueue = rotateMoversQueue(
      queue.movers,
      eventData.activeSession.currentRound
    );

    return {
      movers: roatedMenQueue,
      sitters: queue.sitters,
    };
  };
  const [event, setEvent] = useState({
    id: eventData?.id || null,
    queue: eventData?.queue || {},
    activeSession: eventData?.activeSession || {},
    currentQueue: getRotatedQueue(eventData?.queue),
    movers: eventData?.movers,
    sitters: eventData?.sitters,
  });
  const callCloudFunction = async (functionName, params) => {
    const functionUrl = cloudFunctionsAPI.getUrl(
      functionName,
      cloudFunctionsAPI.locationID
    );

    const body = JSON.stringify(params);

    try {
      const res = await fetch(functionUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      });

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      console.log(`Success: ${res}`);
      return res;
    } catch (error) {
      console.error("Error calling Firebase function:", error);
    }
  };
  const startSession = async () => {
    const confirmStart = window.confirm(
      "Do you want to start the event? The event will start in 1 minute."
    );
    if (confirmStart) {
      setButtonLoading(true);
      const eventID = eventData?.id;
      const currentTime = new Date();
      const res = await callCloudFunction(
        "createScheduleToUpdateCurrentRound",
        {
          eventID,
          currentTime,
        }
      );
      setButtonLoading(false);
      if (res.status == 200) {
        showToast("Event session started successfully");
      } else {
        alert("Error starting event session: " + res.statusText);
      }
    }
  };

  const stopSession = async () => {
    if (window.confirm("Do you want to stop the event?")) {
      setButtonLoading(true);
      const params = {
        eventID: eventData?.id,
        taskID: eventData?.activeSession.taskID,
      };
      const res = await callCloudFunction("stopEventSession", params);
      setButtonLoading(false);
      if (res.status == 200) {
        showToast("Event session stopped successfully");
      } else {
        alert("Error starting event session: " + res.statusText);
      }
    }
  };

  useEffect(() => {
    setEvent({
      id: eventData?.id || null,
      queue: eventData?.queue || {},
      activeSession: eventData?.activeSession || {},
      currentQueue: getRotatedQueue(eventData?.queue),
      movers: eventData?.movers,
      sitters: eventData?.sitters,
    });
    setQueueLength(eventData.queue?.movers?.length || 0);
  }, [eventData]);

  useEffect(() => {
    if (eventData?.queue) {
      const movers = eventData?.movers;
      const sitters = eventData?.sitters;
      const moversQueue = eventData?.queue?.movers;
      const sittersQueue = eventData?.queue?.sitters;

      if (!moversQueue || !sittersQueue) {
        return;
      }

      setLateComers(getAddedUsers(movers, sitters, moversQueue, sittersQueue));
      setRemovedUsers(getRemovedUsers(movers, sitters, moversQueue, sittersQueue));
      setQueueLength(eventData.queue?.movers?.length || 0);
    }
  }, [eventData?.queue, eventData?.movers, eventData?.sitters]);

  const [queueUnsaved, setQueueUnsaved] = useState(false);
  const hasUsers = (group) => Array.isArray(group) && group.length > 0;

  const groupHasUsers = (group) =>
    group && (hasUsers(group.movers) || hasUsers(group.sitters));

  // Regenerate queue with late comers.
  const handleRegenerateQueue = async () => {
    let newQueue = { ...event.queue };
    if (groupHasUsers(lateComers) && newQueue) {
      try {
        newQueue = await regenerateQueue(
          event,
          lateComers,
          eventData.activeSession.currentRound
        );
      } catch (error) {
        alert(error.message);
      }
    }

    if (groupHasUsers(removedUsers)) {
      newQueue = removeRemovedUsersFromQueue(newQueue, removedUsers);
    }

    setNewQueue(newQueue);
    setQueueUnsaved(true);
  };

  const handleGenerateQueue = async () => {
    const movers = [...event.movers];
    const sitters = [...event.sitters];
    const newQueue = await generateQueue(movers, sitters);
    setQueueUnsaved(true);

    setEvent({
      ...event,
      queue: newQueue,
      currentQueue: getRotatedQueue(newQueue),
    });
  };

  const deleteQueue = () => {
    if (window.confirm("Are you sure?")) {
      const docRef = doc(db, "events", event.id);
      updateDoc(docRef, {
        queue: null,
      });
      navigate(`/events/${event.id}/edit`);
    }
  };
  const saveQueue = async () => {
    const docRef = doc(db, "events", event.id);
    if (newQueue) {
      await updateDoc(docRef, {
        queue: newQueue,
      });
      setNewQueue(null);
    } else {
      await updateDoc(docRef, {
        queue: event.queue,
      });
    }

    setQueueUnsaved(false);
  };

  const getActiveUsersCount = (activeSession) => {
    if (!activeSession) return [];
    const sessionFields = ["at", "timeLeft", "currentRound"];
    const participants = Object.keys(activeSession).filter(
      (f) => sessionFields.indexOf(f) == -1
    );
    return participants;
  };

  if (loading) {
    return (
      <div>
        <Loader></Loader>
      </div>
    );
  }

  function convertSeconds(timeLeftInSec) {
    if (
      timeLeftInSec == undefined ||
      timeLeftInSec == null ||
      timeLeftInSec.length == 0
    )
      return "";

    const elapsedTime =
      (Date.now() - Utility.convertDate(event.activeSession.at).getTime()) /
      1000;
    const currentTimeLeft = timeLeftInSec - elapsedTime;

    if (currentTimeLeft < 60) {
      return `${Math.floor(currentTimeLeft)} sec`;
    }

    const minutes = Math.floor(currentTimeLeft / 60);
    const remainingSeconds = Math.floor(currentTimeLeft % 60);
    return `${minutes}m ${remainingSeconds}s`;
  }

  const onUpdateMetric = async (
    field,
    oldValue,
    newValue,
    cancelExistingTask = false
  ) => {
    if (!Number.isNaN(newValue) && Number(newValue) > 0) {
      newValue = Number(newValue);
      if (oldValue === newValue) return;
    } else {
      return;
    }
    setNewQueue(null);
    await EventAPI.updateEvent(eventData.id, {
      [`activeSession.${field}`]: newValue,
    });

    if (cancelExistingTask) {
      const params = {
        eventID: eventData?.id,
        cancelExistingTask,
      };

      await callCloudFunction("updateCurrentRound", params);
    }
  };

  const increaseRound = (metric) => {
    adjustRound(metric + 1);
  };
  const increaseTimeLeft = (metric) => {
    adjustTimeLeft(metric + 60);
  };

  const decreaseRound = (metric) => {
    adjustRound(metric - 1);
  };
  const decreaseTimeLeft = (metric) => {
    adjustTimeLeft(metric - 60);
  };

  const adjustTimeLeft = (newValue) => {
    onUpdateMetric("timeLeft", event.activeSession?.timeLeft, newValue, true);
    setEditEnabled(false);
  };
  const adjustRound = (newValue) => {
    onUpdateMetric("currentRound", event.activeSession?.currentRound, newValue);
    setEditEnabled(false);
  };

  return (
    <div className={`${eventData.isCompleted ? "opacity-50" : ""}`}>
      <div className="grid lg:grid-cols-2 gap-4">
        {groupHasUsers(lateComers) && (
          <div className="mb-4">
            <h1 className="w-full text-center my-2 text-gray-500">
              Waiting to Join...
            </h1>
            <Waitlist
              lateComers={lateComers}
              eventUsersProfile={eventUsersProfile}
            />
          </div>
        )}
        {groupHasUsers(removedUsers) && (
          <div className="mb-4">
            <h1 className="w-full text-center my-2 text-gray-500">
              Removed users
            </h1>
            <RemovedList removedUsers={removedUsers} />
          </div>
        )}
      </div>

      {event.activeSession && Object.keys(event.activeSession).length !== 0 && (
        <div>
          {event.activeSession?.currentRound >= 0 && (
            <Field className="flex items-center justify-end mb-2">
              <Label as="span" className="mr-2 text-sm">
                <span className="text-gray-500 text-sm">Edit</span>
              </Label>
              <Switch
                checked={editEnabled}
                onChange={setEditEnabled}
                className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none  data-[checked]:bg-primary"
              >
                <span
                  aria-hidden="true"
                  className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                />
              </Switch>
            </Field>
          )}
          <div className="justify-evenly grid grid-cols-2 gap-4">
            <SessionMetrics
              editEnabled={editEnabled}
              name={`Round`}
              metric={event.activeSession?.currentRound}
              increaseMetric={increaseRound}
              decreaseMetric={decreaseRound}
              formatMetric={(metric) => `${metric} / ${queueLength}`}
              loading={loading}
            />
            <SessionMetrics
              editEnabled={editEnabled}
              name={"Time"}
              metric={event.activeSession?.timeLeft}
              increaseMetric={increaseTimeLeft}
              decreaseMetric={decreaseTimeLeft}
              formatMetric={convertSeconds}
              loading={loading}
              renderEverySec={true}
              reRenderOnThisValueChange={event.activeSession?.at}
            />
          </div>
        </div>
      )}
      {event.currentQueue && Object.keys(event.currentQueue).length !== 0 ? (
        <div className={`mt-6`}>
          <QueueTable
            initialGroupA={event.currentQueue.movers}
            initialGroupB={event.currentQueue.sitters}
            activeSession={event.activeSession}
          />
        </div>
      ) : (
        <p className="text-gray-400 mt-3">No queue exists</p>
      )}
      {newQueue && (
        <div className={`mt-6`}>
          <h1 className="text-lg font-bold mb-4">New Queue</h1>
          <QueueTable
            initialGroupA={getRotatedQueue(newQueue).movers}
            initialGroupB={getRotatedQueue(newQueue).sitters}
            activeSession={event.activeSession}
          />
        </div>
      )}
      <div className="mt-6 md:flex items-center justify-between">
        {!queueUnsaved && event.currentQueue ? (
          <LinkButton onClick={deleteQueue}>Delete queue</LinkButton>
        ) : (
          <p></p>
        )}

        <div className="flex items-center gap-x-6 mt-2 lg:mt-0">
          {queueUnsaved && event.currentQueue && (
            <SecondaryButtonSmall onClick={saveQueue}>
              Save new queue
            </SecondaryButtonSmall>
          )}

          {!eventData.isCompleted && (
            <SecondaryButtonSmall
              onClick={
                event.currentQueue && event.activeSession?.taskID
                  ? handleRegenerateQueue
                  : handleGenerateQueue
              }
            >
              {event.currentQueue ? "Re-generate queue" : "Generate queue"}
            </SecondaryButtonSmall>
          )}
          {event.currentQueue && !event.activeSession?.taskID && (
            <PrimaryButtonSmall onClick={startSession} loading={buttonLoading}>
              Start Event Session
            </PrimaryButtonSmall>
          )}
          {event.currentQueue &&
            event.activeSession?.taskID &&
            !eventData.isCompleted && (
              <SecondaryDangerButtonSmall
                onClick={stopSession}
                loading={buttonLoading}
              >
                Stop event
              </SecondaryDangerButtonSmall>
            )}
          {event.currentQueue &&
            event.activeSession?.taskID &&
            eventData.isCompleted && (
              <p className="text-gray-400 text-center ">This event has ended</p>
            )}
        </div>
      </div>
    </div>
  );
};
