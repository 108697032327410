import React, { useState } from "react";
import UserAPI from "../api/UserApi";
import { useEffect } from "react";

const TagInputWithAutocomplete = ({
  label = "Tags",
  placeholder = "Add new tag",
  value,
  onChange,
  options = [],
  usersDb = [],

}) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);

  // const userMap = new Map(usersDb.map((user) => [user.userID, user]));

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Debounce input value
  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputValue) {
        fetchFilteredUsers(inputValue);
      } else {
        setFilteredUsers([]);
      }
    }, 300); // Adjust the timing as anecessary

    return () => clearTimeout(timer);
  }, [inputValue]);

  const fetchFilteredUsers = (inputValue) => {
    setLoading(true);
    try {
      UserAPI.getUserByPartialName(inputValue).then((users) => {

        setFilteredUsers(users);
      });
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && inputValue && !filteredUsers.length) {
      e.preventDefault();
      if (!value.includes(inputValue)) {
        onChange([...value, inputValue]);
      }
      setInputValue("");
    }
  };

  const handleSelectUser = (userID) => {
    if (!value.includes(userID)) {
      onChange([...value, userID]);
    }
    setInputValue("");
    setFilteredUsers([]);
  };

  const handleRemoveTag = (tag) => {
    onChange(value.filter((val) => val !== tag));
  };

  const getTagLabel = (tag) => {
    const user = usersDb.find((user) => user.userID === tag);
    if (user) {
      return user.name;
    }
  };

  return (
    <div className="mb-4">
      <label
        htmlFor={`${label}-input`}
        className="block text-sm font-medium text-gray-700 mb-2"
      >
        {label}
      </label>
      <div className="flex flex-wrap items-center border rounded-md p-1">
        {value.map((tag, index) => (
          <Tag
            index={index}
            tag={tag}
            getTagLabel={getTagLabel}
            handleRemoveTag={handleRemoveTag}
            key={index}
          />
        ))}
        <input
          id={`${label}-input`}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          className="flex-grow border-none focus:ring-0 outline-none"
        />
        {loading && (
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-primary"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        {filteredUsers.length > 0 && (
          <div className="z-10 bg-white w-full border border-gray-300 rounded-md mt-1">
            {filteredUsers.map((user) => (
              <div
                key={user.userID}
                onClick={() => handleSelectUser(user.userID)}
                className="p-2 cursor-pointer hover:bg-gray-100"
              >
                {user.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TagInputWithAutocomplete;

function Tag({ index, tag, getTagLabel, handleRemoveTag }) {
  const [showUserID, setShowUserID] = useState(false);
  return (
    <div
      key={index}
      className="flex items-center bg-gray-200 rounded-full px-2 py-1 m-1"
    >
      <span
        className="text-sm text-gray-700"
        onClick={(_) => setShowUserID(!showUserID)}
      >
        {showUserID ? tag : getTagLabel(tag)}
      </span>
      <button
        type="button"
        onClick={() => handleRemoveTag(tag)}
        className="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
      >
        &times;
      </button>
    </div>
  );
}
